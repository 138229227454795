import React, { Component } from "react"
import ReleaseListContianer from "./releaseList/releaseListContainer"

class SearchContainer extends Component {
  state = {

  }
  /**
   * React lifecycle method to fetch the data
   */
  componentDidMount() {

  }


  render() {
    let loading, results
    const {searchQuery, searchState} = this.props
    const releases = this.props.searchResults

    console.log('searchState ' + releases.length, searchState)

    if ( searchState === "loading" ) {
      loading = true
    } else {
      loading = false
    }
    
    if ( releases.length > 0 ) {
      console.log('got results')
      results = <ReleaseListContianer 
        releases={releases}
        title="Search Results"
        readyToLoad={!loading}
        searchQuery={searchQuery}
      />
    } else {
      console.log('no results')
      results = <ReleaseListContianer 
      releases={releases}
      title="Search Results"
      readyToLoad={!loading}
      searchQuery={searchQuery}
      errorMessage={"Could not find any releases for " + searchQuery}
    />
    }

    if ( searchState === "empty" ) {
      results = <ReleaseListContianer 
        releases={releases}
        title="Search Results"
        readyToLoad={!loading}
        searchQuery={searchQuery}
        errorMessage={"Search for releases..."}
      />      
    }

    // To Do: Empty state...

    return (
      <div>
        {results}
      </div>
    )
  }
}
export default SearchContainer