import React, { Component } from "react"
import SearchContainer from "../components/searchContainer"
import axios from "axios"
import { getToken } from "../utils/getToken"

class Search extends Component {

  state = {
    searchComplete: false,
    searchResults: [],
    searchActive: true,
    searchQuery: false,
  }

  fetchReleases = async function (url, options) {
    const baseUrl = 'https://api.ochre.io/v1/music/releases?'
    return axios.get(baseUrl + url, options).then(response => {
      return response.data.results
    })
    .catch(error => {
      console.log(error)
    })
  }

  searchQuery(query) {
    const artistQuery = '&artist_name=' + query
    const releaseQuery = '&title=' + query

    getToken().then(options => {
      Promise.all([ 
        this.fetchReleases(artistQuery, options),
        this.fetchReleases(releaseQuery, options)
      ]).then( (results) => {
        const mergedResults = results[0].concat(results[1])
        this.setState({
          searchResults: mergedResults,
          searchQuery: query,
          searchActive: false,
          searchComplete: true,
        })
      })
    })
  }

  initEmptySearchPage() {
    this.setState({
      searchActive: false,     
    })
  }


  componentDidMount() {
    if ( this.props.location.hash ) {
      const query = this.props.location.hash.replace('#','')
      this.searchQuery(query)
    } else {
      this.initEmptySearchPage() 
    }
  }

  render() {
    let searchState
    let {searchComplete, searchActive} = this.state

    if (searchActive & !searchComplete) {
      searchState = "loading"
    }

    if ( !searchActive & searchComplete) {
      searchState = "results"
    }

    if ( !searchActive & !searchComplete ) {
      searchState = "empty"
    }

    return (
      <SearchContainer 
        searchResults={this.state.searchResults}
        searchState={searchState}
        searchQuery={this.state.searchQuery}
      />
    )
  }
}
export default Search
